<template>
  <div class="container">

    <div class="education-program-result mt-4 mb-4">
      <h4 class="text-center mb-4">Формируемые результаты обучения</h4>

      <div class="row mt-4 mb-4" v-if="educationProgramResult_form.educationProgramResult.length == 0">
        <div class="col-md-12">
          <Button type="button" icon="pi pi-plus" label="Добавить результаты обучения" @click="openAddResultDialog"/>
        </div>
      </div>


      <div v-if="educationProgramResult_form.educationProgramResult.length != 0">
<!--        <div class="row mt-4" v-for="n in 8" :key="n">-->
<!--          <label class="col-md-2 col-form-label">№ {{ n }}</label>-->
<!--          <div class="col-md-6">-->
<!--              <textarea class="form-control"-->
<!--                        placeholder="Результат обучения"-->
<!--                        :value="educationProgramResult_form.educationProgramResult.find(i=> i.number == n).text"-->
<!--                        @input="changeUpdateEducationProgramResult(n, 'text', $event)">-->
<!--              </textarea>-->
<!--          </div>-->
<!--          <div class="col-md-4">-->
<!--            <input type="text" class="form-control" placeholder="Код"-->
<!--                   :value="educationProgramResult_form.educationProgramResult.find(i=> i.number == n).code"-->
<!--                   @input="changeUpdateEducationProgramResult(n, 'code', $event)">-->
<!--          </div>-->

<!--        </div>-->

        <!--           table start-->
        <div class="table-responsive-md mt-4">
          <table class="table table-striped table-bordered">
            <thead>
            <tr>
              <th scope="col">№</th>
              <th scope="col">Результат обучения</th>
              <th scope="col">Код</th>
            </tr>
            </thead>
            <tbody>

            <tr v-for="n in 12" :key="n">
              <th scope="row">{{ n }}</th>
              <td>
              <textarea class="form-control"
                        placeholder="Результат обучения"
                        :value="educationProgramResult_form.educationProgramResult.find(i=> i.number == n).text"
                        @input="changeUpdateEducationProgramResult(n, 'text', $event)">
              </textarea>
              </td>
              <td>
                <input type="text" class="form-control" placeholder="Код"
                       :value="educationProgramResult_form.educationProgramResult.find(i=> i.number == n).code"
                       @input="changeUpdateEducationProgramResult(n, 'code', $event)">
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <!--           table end-->






        <div class="col-md-12 mt-4 mb-4 text-center" style="min-height: 120px">
          <button type="button" class="btn btn-primary" @click="updateEducationProgramResult">Обновить
          </button>
        </div>
      </div>





      <Dialog v-model:visible="addResultDisplay" :style="{width: '50vw'}" :closable="false">
        <template #header>
          <h5>Добавить результаты обучения</h5>
        </template>

        <div class="col-md-12 mt-4">

<!--          <div class="row mt-4" v-for="n in 8" :key="n">-->
<!--            <label class="col-md-2 col-form-label">№ {{ n }}</label>-->
<!--            <div class="col-md-6">-->
<!--              <textarea class="form-control"-->
<!--                      placeholder="Результат обучения"-->
<!--                        @input="changeEducationProgramResult(n, 'text', $event)">-->
<!--              </textarea>-->
<!--            </div>-->
<!--            <div class="col-md-4">-->
<!--              <input type="text" class="form-control" placeholder="Код"-->
<!--                     @input="changeEducationProgramResult(n, 'code', $event)">-->
<!--            </div>-->

<!--          </div>-->




<!--           table start-->
                      <div class="table-responsive-md mt-4">
                          <table class="table table-striped table-bordered">
                              <thead>
                              <tr>
                                  <th scope="col">№</th>
                                  <th scope="col">Результат обучения</th>
                                  <th scope="col">Код</th>
                              </tr>
                              </thead>
                              <tbody>

                              <tr v-for="n in 12" :key="n">
                                  <th scope="row">{{ n }}</th>
                                  <td>
                                      <textarea class="form-control"
                                                placeholder="Результат обучения"
                                                @input="changeEducationProgramResult(n, 'text', $event)">
                                      </textarea>
                                  </td>
                                  <td>
                                    <input type="text" class="form-control" placeholder="Код"
                                           @input="changeEducationProgramResult(n, 'code', $event)">
                                  </td>
                              </tr>
                              </tbody>
                          </table>
                      </div>
<!--           table end-->


        </div>

        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" class="p-button-text"
                  @click="closeAddResultDialog"/>
          <Button label="Сохранить" icon="pi pi-check" @click="submitEducationProgramResult"/>
        </template>
      </Dialog>





    </div>
  </div>
</template>


<script>

import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "EducationProgramResult",
  data() {
    return {
      urlEducationProgramId: +this.$route.query.education_program_id || 0,
      addResultDisplay: false,
      educationProgramResultForm: []
    }
  },
  computed: {
    ...mapState('educationProgramResult', ['educationProgramResult_form']),

  },

  methods: {
    ...mapActions('educationProgramResult', ['GET_EDUCATION_PROGRAM_RESULT', 'POST_EDUCATION_PROGRAM_RESULT','PUT_EDUCATION_PROGRAM_RESULT']),
    ...mapMutations('educationProgramResult', ['SET_UPDATE_EDUCATION_PROGRAM_RESULT']),
    openAddResultDialog() {
      this.addResultDisplay = true
      for(let i = 1; i < 13; i++) {
        this.educationProgramResultForm.push({
          text: '',
          code: '',
          number: i,
          education_program_id: this.urlEducationProgramId
        })
      }
      console.log(this.educationProgramResultForm, 'educationProgramResultForm')
    },
    closeAddResultDialog() {
      this.addResultDisplay = false
      this.educationProgramResultForm = []
    },

    changeEducationProgramResult(number, property, e, val = 'value') {
      const value = e.target[val]
      this.educationProgramResultForm.find(i=> i.number == number)[property] = value

      console.log(this.educationProgramResultForm, 'educationProgramResultForm')

    },
    changeUpdateEducationProgramResult(number, property, e, val = 'value') {
      const value = e.target[val]
      this.SET_UPDATE_EDUCATION_PROGRAM_RESULT({number, property, value})

    },


    async submitEducationProgramResult() {
      this.addResultDisplay = false
      let data = await this.POST_EDUCATION_PROGRAM_RESULT(this.educationProgramResultForm)
      if (data) {
        await this.GET_EDUCATION_PROGRAM_RESULT(this.urlEducationProgramId)
        this.$message({text: 'Сохранено'})
        this.educationProgramResultForm = []

      }
    },
    async updateEducationProgramResult() {

      let data = await this.PUT_EDUCATION_PROGRAM_RESULT()
      if (data) {
        await this.GET_EDUCATION_PROGRAM_RESULT(this.urlEducationProgramId)
        this.$message({text: 'Обновлено'})
      }
    }

  },

  async mounted() {
    await this.GET_EDUCATION_PROGRAM_RESULT(this.urlEducationProgramId)

  }
}
</script>

<style scoped>
</style>